<template>
  <div class="order" v-if="order">
    <Head :type="0">
      <template #title> 订单详情 </template>
    </Head>
    <div class="main">
      <div class="address">
        <img src="../../../assets/maker/makerLocation.png" alt="" />
        <div class="address-box">
          <div class="title">
            <div
              class="lable"
              style="background: #ff0038"
              v-if="order.isDefault === '1'"
            >
              默认
            </div>
            <div class="lable" style="background-color: #3e85ff" v-if="false">
              家
            </div>
            <span>
              {{ order.provinceName + order.cityName + order.countyName }}</span
            >
          </div>
          <div class="middle">
            {{ order.detailInfo }}
          </div>
          <div class="user">
            <div class="name">{{ order.userName }}</div>
            <div class="name">{{ order.telNum }}</div>
          </div>
        </div>
      </div>
      <div class="warp">
        <div class="shop-name">{{ order.sellShopName }}</div>

        <div class="good-box" @click="goShop()">
          <img :src="order.picUrl" alt="" />

          <div class="good-info">
            <div class="good-title">
              <div class="spu-name">
                <div class="van-multi-ellipsis--l2">{{ order.spuName }}</div>
              </div>
              <div class="price">
                ￥<span>{{ order.paymentPrice }}</span>
              </div>
            </div>
            <div class="lable">
              规格：
              <div style="overflow: auto; flex: 1">
                {{ order.specInfo || "无" }}
              </div>
              <span>x{{ order.quantity }}</span>
            </div>
          </div>
        </div>
        <div class="li">
          <div class="left">运费</div>
          <div class="right">￥{{ order.freight }}</div>
        </div>
        <div class="li">
          <div class="left">商品总价</div>
          <div class="right">￥{{ order.paymentPrice }}</div>
        </div>
        <div class="li">
          <div class="left">订单总价</div>
          <div class="right">￥{{ order.paymentPrice + order.freight }}</div>
        </div>
        <div class="li">
          <div class="left">
            {{ order.status == null ? "待付款" : "已付款" }}
          </div>
          <div class="right">￥{{ order.paymentPrice + order.freight }}</div>
        </div>
      </div>
      <div class="desc">
        <div class="title">订单信息</div>

        <ul>
          <li>
            <div class="right">
              <span>订单备注</span>
              <div>：{{ order.remark || "无" }}</div>
            </div>
          </li>

          <li>
            <div class="right">
              <span>订单编号</span>
              <div
                class="van-ellipsis copy"
                :data-clipboard-text="order.orderNo"
                @click="copy"
              >
                ：{{ order.orderNo }}
              </div>
            </div>
          </li>

          <li>
            <div class="right">
              <span>订单交易号</span>
              <div
                class="van-ellipsis copy"
                :data-clipboard-text="order.id"
                @click="copy"
              >
                ：{{ order.id }}
              </div>
            </div>
          </li>

          <li>
            <div class="right">
              <span>创建时间</span>：{{ order.createTime }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div class="btns">
        <div
          class="btn1"
          v-if="
            (order.status === 'success' ||
              order.status == 'waitsellersend' ||
              order.status == 'waitbuyerreceive') &&
            order.refundStatus == '0'
          "
          @click="
            $router.push({ path: 'makerOrderRefund', query: { id: order.id } })
          "
        >
          申请退款
        </div>
        <div
          class="btn1"
          v-if="order.status === 'waitbuyerreceive'"
          @click="
            $router.push({
              path: 'logistics',
              query: {
                id: '2065949238035671648',
                type: 1,
                orderNo: order.orderNo,
                address:
                  order.provinceName +
                  order.cityName +
                  order.countyName +
                  order.detailInfo,
              },
            })
          "
        >
          查看物流
        </div>
        <!-- item.alibabaId -->
        <div class="btn1" v-if="order.status == null" @click="cancel(5)">
          取消订单
        </div>

        <div
          class="btn1"
          v-if="order.refundStatus != null && order.refundStatus != 0"
          @click="
            $router.push({
              path: 'makerRefundDetail',
              query: { price: order.paymentPrice, orderNo: order.refundId },
            })
          "
        >
          查看详情
        </div>

        <div
          class="btn2"
          v-if="
            (order.status === 'waitsellersend' ||
              order.status === 'waitbuyerreceive') &&
            order.refundStatus == '0'
          "
          @click="cancel(4)"
        >
          确认收货
        </div>
        <div
          class="btn2"
          v-if="order.status == null && timer"
          @click="showPaul = true"
        >
          付款
        </div>
        <!-- <div
          class="btn2"
          v-if="
            order.status === '5' ||
            order.status === 'confirm_goods_and_has_subsidy' ||
            order.refundStatus == 'refundclose' ||
            order.refundStatus == 'refundsuccess' ||
            (!timer && order.status == null)
          "
          @click="goShop()"
        >
          再次购买
        </div> -->
      </div>
    </div>
    <van-action-sheet v-model="showPaul" title="支付">
      <div class="content">
        <van-radio-group v-model="payType">
          <!-- <div class="qianbao">
                        <div class="left">
                             <img src="../../../assets/maker/qianbao.png" alt="" /> 钱包支付
                        </div> 
                        <van-switch v-model="checked" size="0.5rem" /> 

                     </div> -->
          <!-- <span class="line"></span> -->

          <van-cell-group>
            <van-cell clickable @click="payType = '1'">
              <template #title>
                <img
                  class="img"
                  src="../../../../public/img/pay/alipay-icon.png"
                  alt
                />
                <span class="custom-title">支付宝</span>
              </template>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <span class="line"></span>

            <van-cell clickable @click="payType = '2'">
              <template #title>
                <img
                  class="img"
                  src="../../../../public/img/pay/weChat-icon.png"
                  alt
                />
                <span class="custom-title">微信</span>
              </template>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="pay" @click="pay()">支付</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Head from "../../../components/head/index.vue";
import { orderDetail } from "../../../api/maker/order.js";
import { edit } from "../../../api/maker/order";
import { wxPay, aliPay } from "../../../api/maker/pay";

export default {
  data() {
    return {
      order: null, // 订单数据
      payType: "1", //支付方式
      showPaul: false, // 显示支付面板
      timer: false,
      timerId: 0,
    };
  },

  methods: {
    //获取订单详情数据
    getInfo() {
      orderDetail(this.$route.query.id).then((res) => {
        if (res.data.code === 0) {
          this.order = res.data.data;
          this.order.freight = Number(this.order.freight);
          if (this.order.status === null) {
            this.time();
          }
        }
      });
    },

    goShop() {
      if (!this.order.makerBusinessId) {
        this.$toast.fail("商品已下架");

        return;
      }
      this.$router.push({
        path: "makerGoodsDetail",

        query: { id: this.order.makerBusinessId },
      });
    },

    // 取消订单/确认收货
    cancel(type) {
      edit({ id: this.order.id, status: type + "" }).then((res) => {
        if (res.data.code === 0) {
          this.order.status = type + "";

          this.$toast.success("取消成功");
        } else {
          this.$$toast.fail("取消失败");
        }
      });
    },
    pay() {
      this.showPaul = false;
      window.closePage = this.closePage;

      let arr = [];

      arr.push(this.order.id);

      if (this.payType === "1") {
        aliPay(arr).then((res) => {
          this.openPay(res, "2");
        });
        return;
      }

      wxPay(arr).then((res) => {
        if (res.data.code === 0) {
          this.openPay(res, "1");
        }
      });
    },

    closePage(data) {
      !data && this.getInfo();
    },

    time() {
      let createT = new Date(this.order.createTime.replace(/-/gi, "/"));

      if (Date.now() - createT - 15 * 60 * 1000 < 0) {
        this.timer = true;
      }

      if (!this.timer) return;

      this.timerId = setInterval(() => {
        if (Date.now() - createT - 15 * 60 * 1000 < 0) {
          this.timer = true;
        } else {
          this.timer = false;
          clearInterval(this.timerId);
        }
      }, 1000);

      console.log(this.timer);
    },
    copy() {
      let clipboard = new this.Clipboard(".copy");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  created() {
    this.setRem();
    this.getInfo();
  },

  components: {
    Head,
  },
};
</script>

<style lang="less" scoped>
.order {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: PingFangSC-Medium, PingFang SC;
  background-color: white;
  .main::-webkit-scrollbar {
    display: none;
  }
  .main {
    background-color: #f6f6f6;
    flex: 1;
    overflow: auto;
    border-top: 1px solid #eee;
    background-color: #f6f6f6;
    padding: 0.266667rem;
    .address {
      display: flex;
      align-items: center;
      padding-bottom: 0.4rem;
      background-color: white;
      padding: 0.4rem 0.426667rem;
      margin-bottom: 0.266667rem;
      border-radius: 0.266667rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        margin-right: 0.4rem;
      }
      .title {
        display: flex;
        align-items: center;
        color: #333;
        font-size: 0.32rem;
        margin-bottom: 0.213333rem;
        .lable {
          padding: 0 5px;
          color: white;
          border-radius: 0.066667rem;
          margin-right: 0.133333rem;
        }
      }
      .middle {
        color: #333;
        font-size: 0.373333rem;
        font-weight: 500;
      }
      .user {
        display: flex;
        .name {
          color: #333;
          font-size: 0.32rem;
          margin-right: 0.266667rem;
        }
      }
    }
    .warp {
      background-color: white;
      padding: 0.32rem 0.426667rem;

      border-radius: 0.266667rem;
      .shop-name {
        color: #333333;
        font-size: 0.373333rem;
        margin-bottom: 0.48rem;
      }
      .good-box {
        display: flex;
        margin-bottom: 0.566667rem;
        img {
          width: 2.133333rem;
          height: 2.133333rem;
          margin-right: 0.266667rem;
          border-radius: 0.266667rem;
        }
        .good-info {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-between;
        }
        .good-title {
          display: flex;
          justify-content: space-between;
          .price {
            color: #a80000;
            font-size: 0.373333rem;
            span {
              font-size: 0.48rem;
            }
          }
        }
        .lable {
          color: #999;
          padding-bottom: 0.133333rem;
          display: flex;
        }
      }
      .li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.266667rem;
        color: #333333;
        font-size: 0.373333rem;
      }
    }
    .desc {
      background-color: white;
      margin-top: 0.266667rem;
      padding: 0.266667rem 0.426667rem;
      border-radius: 0.266667rem;
      box-sizing: border-box;
      .title {
        color: #333;
        font-size: 0.373333rem;
        margin-bottom: 0.293333rem;
      }
      li {
        padding: 0.133333rem 0;
        color: #333333;
        font-size: 0.373333rem;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        span {
          display: block;
          width: 2.2rem;
          white-space: nowrap;
          box-sizing: border-box;
          padding-right: 0.35rem;
        }
        .right {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
        }
      }
    }
  }
  .footer {
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    position: relative;

    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.266667rem;
      div {
        margin-right: 0.266667rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.266667rem;
        width: 2.24rem;
        height: 0.746667rem;
        font-size: 0.373333rem;
      }
      .btn1 {
        border: 1px solid #999999;
        box-sizing: border-box;

        color: #999;
      }
      .btn2 {
        color: white;
        background-image: linear-gradient(to right, #ff7200, #ff3c00);
      }
    }
  }
}
.content {
  padding: 0.213333rem 0.213333rem 0;
  height: 6.666667rem;

  img {
    width: 0.666667rem;
    height: 0.666667rem;
    margin-right: 0.266667rem;
  }
  .qianbao {
    padding: 0.266667rem 0;
    margin: 0 0.4rem;
    flex: 1;
    display: flex;
    align-items: center;

    justify-content: space-between;
    border-bottom: 1px solid #f6f6f6;
  }
  .left {
    display: flex;
    align-items: center;
  }

  /deep/ .van-cell__title {
    display: flex;
    align-content: center;
  }
  .pay {
    width: 8.24rem;
    height: 0.96rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.4rem;
    font-weight: 500;
    background-color: #e64d42;
    margin: 1.033333rem auto 0.266667rem;
    border-radius: 0.56rem;
  }
}
/deep/ .van-overlay {
  transition: 0.5s linear;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
/deep/ [class*="van-hairline"]::after {
  border: none !important;
}
</style>